import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { get } from 'lodash';
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { setMessage } from '../redux/shared/actions';
import store from '../store';
import { cacheManager, clearSession, getUserInfo, removeAppCookies } from './utilCommon';
import { LOG_TYPE, logger } from './dataDog.logger';

// Setup Configuration for OktaAuth. 
export const OIDC = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true, // [true] provides a modern solution for protecting SPAs. 
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_URI,
    disableHttpsCheck: window.location.hostname !== 'localhost' ? true : false, // [True] if working on https environment.
    tokenManager: {
        expireEarlySeconds: 60,
        autoRenew: true, // autorenew [true] auto update access token
        secure: window.location.hostname !== 'localhost' ? true : false, // This option is only relevant if storage is set to cookie and false option is only for testing
    },
    services: {
        autoRenew: true,
        autoRemove: true,
    }
});

export const handleLoggedIn = async () => {
    window.location.assign(process.env.REACT_APP_LOGOUT_URI);
}

const clearSessionHandler = (isLogin = false) => {
    clearSession();
    removeAppCookies();
    if(isLogin) handleLoggedIn();
}

// Logout Handler
export const logoutHandler = async () => {
    window.localStorage.setItem('isMemberStillLoginInAnotherTab', false);
    try {
        OIDC.signOut('/');
    } catch (error) {
        console.error(error);
    }
}

export const redirectUrlHandler = () => {
    const redirectUrl = sessionStorage.getItem("REDIRECT_URL");
    return redirectUrl || null;
}

export const oktaExceptionHandler = (error) => {
    logger(
        `OAuthError or AuthSdkError Error: ${error.name}`,
        {
            error,
            config: error.config,
        },
        LOG_TYPE.error,
        {
            error
        }
    );
    cacheManager.clearSession();
    handleLoggedIn(window.location.search);
}

const oktaWithRedirectHandler = () => {
    const hashValue = window.location.hash;
    const sessionToken = hashValue ? window.location.hash.split('#')[1] : false;
    try {
        OIDC.token.getWithRedirect({
            scopes: OIDC.options.scopes,
            ...(sessionToken && {sessionToken})
        });
    } catch (error) {
        oktaExceptionHandler(error)
    }
}

// TODO : set auth token header.
export const getAuthHeaderToken = async () => {
    try {
        const tokenInfo = await OIDC.tokenManager.get("accessToken");
        // To check token is valid.
        if (tokenInfo) {
            if (OIDC.tokenManager.hasExpired(tokenInfo)) {
                const renewAccessToken = await OIDC.token.renew(tokenInfo);
                return renewAccessToken ? renewAccessToken.accessToken : null;
            } else {
                return tokenInfo.accessToken;
            }
        } else {
            return null;
        }
    } catch (error) {
        oktaExceptionHandler(error);
    }
};

// TODO : Check ME call on Routes Change
export const verifyMECallForSession = () => {
    const userInfo = getUserInfo();
    if (userInfo && get(OIDC, 'session')) {
        OIDC.session.get().then(async sessionInfo => {
            const tokenInfo = await getAuthHeaderToken();
            // Clear Cookies if cross tab along with [expired to new session] will be apply.
            if (!sessionInfo) {
                // Forcefully remove storages if no session exists.
                store.dispatch(setMessage(true, '401'));
                clearSessionHandler(true);
            } else if (userInfo.email && userInfo.email !== sessionInfo.login) {
                clearSessionHandler(true);
            } else if (sessionInfo && !tokenInfo) {
                // Remove garbage storage if already exists.
                clearSessionHandler(true);
            }
        })
    }
}



// TODO : Render security component from common place.
export const BrowserRoutesProvider = ({ OIDC_CONFIG, ...props }) => {
    const history = useHistory();
    const hashValue = window.location.hash;

    React.useEffect(() => {
        // https://github.com/okta/okta-auth-js/issues/1365#issuecomment-1387698694
        (async () => {
            await OIDC.start();
        })();

        return () => {
            (async () => {
                await OIDC.stop();
            })();
        }
    }, []);

    React.useEffect(() => {
        if (hashValue) {
            const urlParams = new URLSearchParams(window.location.search);
            const redirectUrlParams = urlParams.get('callbackUrl');
            window.sessionStorage.setItem("REDIRECT_CALLBACK_URL", window.location.pathname?.length ? redirectUrlParams : PROTACTED_HOME_PAGE);
            oktaWithRedirectHandler();
        }
    }, [hashValue]);

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        const redirectUrl = window.sessionStorage.getItem("REDIRECT_CALLBACK_URL");
        history.replace(toRelativeUrl(redirectUrl || originalUri || '/', window.location.origin));
        window.sessionStorage.removeItem("REDIRECT_CALLBACK_URL");
    };

    const handleAuth = async () => {
        if (!hashValue) {
            const isSessionExist = await OIDC.session.exists();
            if (!isSessionExist) {
                const callbackUrl = redirectUrlHandler();
                if (callbackUrl) {
                    clearSessionHandler(true);
                } else {
                   handleLoggedIn(window.location.pathname + window.location.search);
                }
            } else {
                setTimeout(() => {
                    const idToken = OIDC.getIdToken();
                    if (!idToken) {
                        oktaWithRedirectHandler();
                    }
                }, 1000);
            }
        }
    };
    return (
        <Security oktaAuth={OIDC_CONFIG} onAuthRequired={handleAuth} restoreOriginalUri={restoreOriginalUri}>
            <Route path="/auth" component={LoginCallback} />
            {props.children}
        </Security>
    );
}

export default {
    OIDC,
    BrowserRoutesProvider,
    getAuthHeaderToken,
    verifyMECallForSession
}
