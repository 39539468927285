import { get } from 'lodash';
import { buildURL, callAPI, Urls } from "../helpers/apiUrls";
import { setUserInStorage, getUserInfo, getUUIDNumberFromLocalStorage } from '../helpers/utilCommon';
import { setAppLoader } from '../redux/app/actions';
import { setUserInfo, setUserPermission } from '../redux/login/actions';
import { setMessage } from '../redux/shared/actions';
import { LOGIN_USER_MODEL } from '../models/common.models';

import {
    hideMemberLoader,
    hideSummaryLoader,
    setAllCommitteeList,
    setCommitteeClassificationsList,
    setCommitteeSummary,
    setMemberList,
    setRosterReport,
    setSelectedCommittee,
    setVoteList,
    setVoteListLoader,
    showMemberLoader,
    showSummaryLoader
} from '../redux/roster/actions';

export const checkUser = () => {
    return dispatch => {        
        const userInfo = getUserInfo();
        // TODO : Set userInfo in store.
        dispatch(setUserInfo(userInfo));
        dispatch(setUserPermission(get(userInfo, 'userPermission')));
        dispatch(setAppLoader(false));
    }
}

export const fetchRosterReportAndVote = committeeId => {
    return dispatch => {
        const url = `${buildURL(Urls.GetRosterReportAndVote, null, { committeeId })}`;
        callAPI(url, 'get', null, true, true, (response) => {
            if (response && response.responsestatus) {
                const { responsedata } = response;
                dispatch(setRosterReport(responsedata.committeeroster));
                dispatch(setVoteList(responsedata.vote));
                dispatch(setVoteListLoader(false));
            } else {
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                dispatch(setMessage(true, '402', uuidNumber));
                dispatch(setVoteListLoader(false));
            }
        });
    }
}

export const commonServiceDispatcher = (data, dispatch, committeeId = '', authFlag = false, email = '') => {
    if (data) {
        if (authFlag) {
            let userPermissionObj = data.privilege.applicationmodal;
            const userProfileObj = {
                accountNumber: userPermissionObj.memberaccountnumber,
                memberid: userPermissionObj.memberid,
                userPermission: userPermissionObj,
                demographyDetails: data.privilege.userdetail,
                email,
                IdentityDelegation: data.identitydelegation
            }
            setUserInStorage(userProfileObj);
            dispatch(setUserInfo(userProfileObj));
            dispatch(setUserPermission(userPermissionObj));
            dispatch(setAllCommitteeList(data.committees));
        }
        let defaultSelectedCommitteeid = (committeeId && committeeId != '') ? committeeId : data.committees[0].committeeid;
        dispatch(setSelectedCommittee(defaultSelectedCommitteeid));
        dispatch(setMemberList(data.committeemembers));
        dispatch(setCommitteeSummary(data.committeesummary[0]));
        dispatch(setCommitteeClassificationsList(data.committeeclassification));
        dispatch(fetchRosterReportAndVote(defaultSelectedCommitteeid));
    }
}

export const oktaLoginAction = (data, dispatch, cb) => {
    const url = data['request_mode'] === LOGIN_USER_MODEL.MEMBER_LOGIN ? `${buildURL(Urls.TokenAndRosterPermission, null, null)}` : `${buildURL(Urls.verifyInfo, null, null)}`;
    dispatch(setAppLoader(true));
    callAPI(url, 'post', null, false, true, (response) => {
        if (response && response.responsestatus) {
            const { responsedata } = response;
            commonServiceDispatcher(responsedata, dispatch, '', true, data.userInfo.email);
            cb(true);
            dispatch(setAppLoader(false));
        } else {
            commonServiceDispatcher(null, dispatch, '', true);
            const uuidNumber = getUUIDNumberFromLocalStorage(url);
            dispatch(setMessage(true, 402, uuidNumber));
            cb(false);
        }
    });
}

export const commonCommitteeInfoAction = committeeId => {
    return dispatch => {
        dispatch(showMemberLoader());
        dispatch(showSummaryLoader());
        const url = `${buildURL(Urls.GetCommittInfo, null, { committeeId })}`;

        callAPI(url, 'get', null, true, true, (response) => {
            if (response && response.responsestatus) {
                const { responsedata } = response;
                commonServiceDispatcher(responsedata, dispatch, committeeId);
                dispatch(hideMemberLoader());
                dispatch(hideSummaryLoader());
            } else {
                commonServiceDispatcher(null, dispatch, committeeId);
                const uuidNumber = getUUIDNumberFromLocalStorage(url);
                dispatch(setMessage(true, '402', uuidNumber));
                dispatch(hideMemberLoader());
                dispatch(hideSummaryLoader());
            }
        });
    }
}

export const flagFeature=(callback, dispatch)=>{
    const url = `${buildURL(Urls.flagFeature, null, null)}`;
    dispatch(setAppLoader(true));
    callAPI(url, 'get', null,true, true,(response) => {
      dispatch(setAppLoader(false));
      if (response.result && response.status) {
        callback(response.result);
      }
      else{
       dispatch(setMessage(true, '4003'));
      }
    });
 }