import React, { PureComponent, useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { map } from "lodash";
import PieChartTooltip from '../atoms/PieChartTooltip';
import useMouse from '@react-hook/mouse-position'

// reponsive
import {
  maxWidthValue,
  minWidthValue
} from "../../helpers/constant";
import MediaQuery from "react-responsive";

export const SummaryChart = (props) => {
  const [point, setPoint] = useState({ x: 0, y: 0 });
  const [rightPointer, setRightPointer] = useState(false);
  const ref = React.useRef(null)
  const mouse = useMouse(ref)
  const renderPieChart = (chartWidth, chartHeight, innerPieOuterRadius, outerPieInnerRadius) => {
    const { committeeclassification } = props;
    const { classifications, totalofficialvoters, totalmembers } = committeeclassification;

    let colorsArray = [];
    let officialVoters = [];
    let totalMembersArray = map(classifications, (classObj) => {
      colorsArray.push(classObj.colorcode);
      officialVoters.push({
        name: `${classObj.classificationname} Official Votes`,
        value: classObj.official,
        color: classObj.colorcode,
        strokeColor: classObj.colorcode
      });
      return ({
        name: `Total ${classObj.classificationname}`,
        value: classObj.total,
        color: classObj.colorcode,
        strokeColor: classObj.colorcode
      });
    });
    if(!totalofficialvoters) {
      officialVoters.push({
        name: "#hidden#",
        value: 1,
        color: "#ffffff",
        strokeColor: "#eeeeee"
      });
    }
    if(!totalmembers) {
      officialVoters.push({
        name: "#hidden#",
        value: 1,
        color: "#ffffff",
        strokeColor: "#eeeeee"
      });
      totalMembersArray.push({
        name: "#hidden#",
        value: 1,
        color: "#ffffff",
        strokeColor: "#eeeeee"
      });
    }
    return (
      <PieChart aria-label="Committee classification summary report logo image" width={chartWidth} height={chartHeight}>
        <Pie
          data={totalMembersArray}
          dataKey="value"
          cx='50%'
          cy={chartHeight}
          startAngle={180}
          endAngle={0}
          outerRadius={innerPieOuterRadius}
          opacity="0.5"
        >
          {totalMembersArray.map((entry) => (
            <Cell key={entry.name} fill={entry.color} stroke={entry.strokeColor} />
          ))}
        </Pie>

        <Pie
          data={officialVoters}
          dataKey="value"
          cx='50%'
          cy={chartHeight}
          startAngle={180}
          endAngle={0}
          innerRadius={outerPieInnerRadius}
          outerRadius={chartHeight}
        >
          {officialVoters.map((entry) => (
            <Cell key={entry.name} fill={entry.color} stroke={entry.strokeColor} />
          ))}
        </Pie>
        <Tooltip
          content={(<PieChartTooltip rightPointer={rightPointer} />)}
          wrapperStyle={{
            top: 0,
            left: 0,
            transform: `translate(${point.x}px, ${point.y}px)`,
            zIndex: 2
          }}
        />
      </PieChart>
    );
  }

  const getMouseMovement = (event) => {
    if (!event.isPositionOutside) {
      let { position } = event;
      let rightPointer = false;
      if (position.x > 185) {
        rightPointer = true;
      }
      let tooltipHeight = document.querySelector("#chart-tooltip") ? document.querySelector("#chart-tooltip").offsetHeight : 0;
      position.y -= (tooltipHeight + 28);
      position.x -= rightPointer ? 62 : 88;
      setPoint(position);
      setRightPointer(rightPointer);
    }
  }
  return (
    <div className="chart-display" data-testid="SummaryChartCmp">
      <div ref={ref} onMouseMove={()=>getMouseMovement({position:{x:mouse.x, y:mouse.y}})}>
        <MediaQuery minWidth={minWidthValue.tabletOrDesktop}>
          {renderPieChart(380, 180, 75, 82)}
        </MediaQuery>
        <MediaQuery maxDeviceWidth={maxWidthValue.mobile}>
          {renderPieChart(250, 125, 50, 53)}
        </MediaQuery>
      </div>
  </div>
  )
}

export default SummaryChart;